import { AuthService } from "@adc/utilities/auth-service";
import { AuthStatus } from "@adc/utilities/iauthstatusresponse";
import { LoginStatus } from "@adc/utilities/iloginstatus";
import axios from 'axios';
import * as data from "../../assets/footer-data.json";
import { GlobalDataController } from "../utilities/global-data-controller";
import { TranslationDataController } from '../utilities/translation-data-controller';
import type { IAuthUserResponse } from "@adc/utilities/iauthuserresponse";
import type { ITargetDetails } from "@adc/utilities/itargetDetails";
import type { ReactiveController, ReactiveControllerHost } from "lit";

export class FooterDataController implements ReactiveController {
  authService = new AuthService();
  private readonly host: ReactiveControllerHost;
  public linkData: object;
  public targetAds: object;
  targetDetails : ITargetDetails;
  protocol = document.location.protocol;
  locale = TranslationDataController.retrieveLocale();
  authStatus: AuthStatus = AuthStatus.GUEST;
  loginStatus: string = LoginStatus[0];
  memberInfo: void | IAuthUserResponse;
  aadvantageNumber = '';

  constructor(host: ReactiveControllerHost) {
    (this.host = host).addController(this);
  }

  hostConnected() {
    this.fetchData();
    this.getTarget().then(() => {
      this.host.requestUpdate();
    });
  }

  async fetchData() {
    const baseUrl = document.location.hostname;
    const protocol = document.location.protocol;
    if (baseUrl.includes("localhost") || baseUrl.includes("ideal-succotash")) {
      this.processData(data);
    } else {
      const response = await TranslationDataController.getTranslationData('footer', protocol);
      this.processData(response.data);
      this.host.requestUpdate();
    }
  }

  processData(jsonData) {
    this.linkData = jsonData;
  }

  async getTarget() {
    await this.getAuthStatus();
    if (this.authStatus === AuthStatus.SECURED) {
      try {
        const resp = await this.authService.getMemberInformation();
        this.memberInfo = resp;
        if (this.memberInfo?.aadvantageNumber !== undefined) {
          this.aadvantageNumber = this.memberInfo.aadvantageNumber;
        }
      } catch (err) {
        throw err;
      }
    }
    this.getTargetDetails();
  }
  

  // get login status from loyalty API
  async getAuthStatus() {
    await this.authService.getAuthStatus().then(resp => {
      this.authStatus = resp.status;
      this.loginStatus = LoginStatus[this.authStatus];
    }).catch(err => {
      const baseUrl = document.location.hostname;
      if (baseUrl.includes("aa.com") || baseUrl.includes("american")) {
        this.authStatus = AuthStatus.GUEST;
        this.loginStatus = LoginStatus[this.authStatus];
      }

      console.log('authStatus error, ', err);
    });
  }

  async getTargetDetails() {
    await axios.get<ITargetDetails>(this.buildTargetedUrl()).then(resp => {
      this.targetDetails = resp.data;
      this.host.requestUpdate();
    }).catch(err => {
      console.log("", err);
    });
  }

  private buildTargetedUrl(): string {
    const baseUrl = document.location.hostname;
    const isHomePage = GlobalDataController._isHomePage() ? 'T' : 'F';
    const contentURL = `locale=${this.locale}&loginStatus=${this.loginStatus}&min=1&max=3&subType=FooterMessage1&isHomePage=${isHomePage}&slot=true&aaAdvNum=${this.aadvantageNumber}`;

    let urlToFetch = '';
    if (baseUrl.includes("localhost") || baseUrl.includes("pages.github.io")) {
      // used for local testing
      urlToFetch = `${this.protocol}//aacom-qa-cluster-dal.us-south.containers.appdomain.cloud/tcs/v2/content/MarketingMessages?${contentURL}`;
    } else {
      urlToFetch = `${this.protocol}//${baseUrl}/targeted-content-service/v2/content/MarketingMessages?${contentURL}`;
    }
    return urlToFetch;
  } 

}
