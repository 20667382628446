import { AileronLogger } from 'aileron-logger';
import type { ReactiveElement } from 'lit';

enum LogLevel {
    Disabled = 0,
    Debug = 1,
    Info = 2,
    Warn = 3,
    Error = 4,
}

class LogData {
        status: string;
        componentName: string;
        componentVersion: string;
        loadTime?: number;
        componentStatus: string;
        fullDetails: string;
        location: string;
        level: string;
        QMSessionId?: string;
        QMUserId?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Log(proto: ReactiveElement, _key) {
    // appName should be the client name of this component, for now we're just setting this to AACom for legacy AACom
    const appName = 'AACom-aileron-web';
    const urlToFetch = `${document.location.protocol}//${document.location.hostname}/pubcontent/en_US/fragments/aileron-web/aileron-logger-config.json`;
    let logLevel: LogLevel;
    let stack: string;
    let name: string;
    let message: string;
    let env = 'prod';
    let config;
    let unhandledError = false;

    if (document.location.hostname.includes('stage.aa.com')) {
        env = 'stage';
    } else if (document.location.hostname.includes('qa.aa.com') || document.location.hostname.includes('localhost')) {
        env = 'qa';
    }

    const logger = new AileronLogger(appName, env);
    const data = new LogData();

    const ctor = proto.constructor as typeof ReactiveElement;

    window.onunhandledrejection = (e) => {
        unhandledError = true;
        const { reason } = e || {};
        const stack = reason.stack || 'No stack available';
        const message = reason.message || 'No message available';
        data.fullDetails = `Message: ${message}\nStack: ${stack}`;
    }


    ctor.addInitializer(async (instance: ReactiveElement) => {
        const sessionId = document.cookie.split('; QuantumMetricSessionID=');
        const userId = document.cookie.split('; QuantumMetricUserID=');

        try {
            config = await getLogSwitch(urlToFetch, env);
            logLevel = !unhandledError ? config?.logLevel : LogLevel.Error;
            await instance.updateComplete;

            data.status = !unhandledError ? "success": "Error";
            data.componentName = instance.tagName;
            data.componentVersion = instance['version'];
            data.loadTime = !unhandledError ? instance['loadTime'] : '';
            data.componentStatus = !unhandledError ? "200" : "500";
            data.location = document.location.hostname;
            data.level = !unhandledError ? "Info" : "Error";

            if (sessionId !== null && sessionId.length === 2) {
                data.QMSessionId = sessionId.pop()?.split(';').shift();
            }
            if (userId !== null && userId.length === 2) {
                data.QMUserId = userId.pop()?.split(';').shift();
            }
        } catch(e: any) {
            stack = e.stack;
            name = e.name;
            message = e.message;

            data.status = "Error";
            data.componentName = instance.tagName;
            data.componentVersion = instance['version'];
            data.componentStatus = "500";
            data.fullDetails = stack;
            data.location = document.location.hostname;
            data.level = 'Error';

            if (sessionId !== null && sessionId.length === 2) {
                data.QMSessionId = sessionId.pop()?.split(';').shift();
            }
            if (userId !== null && userId.length === 2) {
                data.QMUserId = userId.pop()?.split(';').shift();
            }
            
            logLevel = LogLevel.Error;
        }

        const randomNum = Math.random();
        const loggingEnabled = config?.loggingEnabled;
        const numerator = config?.gradualRollout?.numerator;
        const shouldLog = randomNum < (numerator / 100);

        if (loggingEnabled) {
            if(logLevel === LogLevel.Disabled) {
                return;
            }

            if (shouldLog) {
                if (logLevel >= LogLevel.Error) {
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    await logger.log(`${instance.tagName} ${instance['version']} ${data.location} ${data.status} ${name} ${message}`, 'Error', data);
                } else if (logLevel >= LogLevel.Warn) {
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    await logger.log(`${instance.tagName} ${instance['version']} ${data.location} ${data.status} ${name} ${message}`, data.level, data);
                } else if (logLevel >= LogLevel.Info) {
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    await logger.log(`${instance.tagName} ${instance['version']} ${data.location} ${data.status} ${data.loadTime}`, data.level, data);
                }
            }
        }
    });

    async function getLogSwitch(url:string, environment: string) {
        return fetch(url).then(resp => resp.json()).then(d => {
            return d.env.find(obj => obj.name.includes(environment));
        }).catch(() => {
            return null;
        });
    }
}
