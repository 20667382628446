import axios from 'axios';
import type { IAuthStatusResponse} from "@adc/utilities/iauthstatusresponse";
import type { IAuthUserResponse } from "@adc/utilities/iauthuserresponse";

export class AuthService {

  getAuthStatus(urlParamValue?: string): Promise<IAuthStatusResponse> {
    const accessLevelURL = '/loyalty/access-level';
    return axios.get<IAuthStatusResponse>((urlParamValue) ? `${accessLevelURL}?locale=${urlParamValue}` : accessLevelURL)
      .then((res) => {
        return res.data;
      })
      .catch(err => {
        //throw this error to next catch in the chain
        throw err;
      });
  }

  getMemberInformation(): Promise<void | IAuthUserResponse> {
    return axios.get<void | IAuthUserResponse>('/loyalty/api/member-information')
      .then(resp => {
        return resp.data;
    }).catch(err => {
      console.log("Loyalty api error", err);
    });
  }
}
