/**
 * The various authentication statuses.
 */
export enum AuthStatus {
  /**
   * The user is not signed in.
   */
  GUEST = '0',

  /**
   * The user is recognized as but not signed in.
   */
  UNSECURED = '1',

  /**
   * The user is signed in.
   */
  SECURED = '2'
}

/**
 * Response Status for Auth
 */
export interface IAuthStatusResponse {
  /**
   * The current authentication status of the user.
   */
  status: AuthStatus;
}
