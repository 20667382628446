/**
 * The various login statuses.
 */

//  {
//     /**
//      * "0" : 'nonmembers' The user is not signed in.
//      */
  
//     /**
//      * '1' = 'unsecure-members' The user is recognized as but not signed in.
//      */
  
//     /**
//      * '2' = 'secure-members' The user is signed in.
//      */
//   }
export const LoginStatus: ILoginStatus = ['nonmembers','unsecure-members','secure-members'];
/**
 * Response Status for Auth
 */
export interface ILoginStatus {
/**
 * The current authentication status of the user.
 */
[index: number]: string;
}
